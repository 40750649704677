import * as React from "react";

function PageNotFoundComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height={533}
      viewBox="0 0 1155 533"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{"404"}</title>
      <defs>
        <path id="404__a" d="M.175.234h168.772V35H.175z" />
        <path id="404__c" d="M0 .434h13.533v17.235H0z" />
        <path id="404__e" d="M.477.517h40.718v41.591H.477z" />
        <path
          d="M7.468 1.277C-3.712 3.943-1.125 16.887 7.3 16.19c.083-.005.169-.012.25-.02 5.326-.412 10.557 1.524 14.53 5.129 7.73 7.016 15.793.55 12.909-7.441C32.218 6.189 22.579.647 12.94.647c-1.84 0-3.681.203-5.472.63"
          id="404__g"
        />
        <path id="404__i" d="M.328.14h82.72V87.29H.328z" />
        <path id="404__k" d="M.62.407h181.53V38H.62z" />
        <path id="404__m" d="M0 .157h103.694V56.65H0z" />
        <path id="404__o" d="M.196.908h46.89v153.981H.196z" />
        <path id="404__q" d="M.06.768h53.873V53.23H.06z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g opacity={0.441} transform="translate(88.25 465)">
          <mask id="404__b" fill="#fff">
            <use xlinkHref="#404__a" />
          </mask>
          <path
            d="M168.947 17.618c0 9.6-37.782 17.383-84.386 17.383-46.607 0-84.386-7.783-84.386-17.383C.175 8.017 37.955.234 84.56.234c46.604 0 84.386 7.783 84.386 17.384"
            fill="#B7B7B7"
            mask="url(#404__b)"
          />
        </g>
        <g transform="translate(40 359.25)">
          <mask id="404__d" fill="#fff">
            <use xlinkHref="#404__c" />
          </mask>
          <path
            d="M9.598 1.915s-6.65-4.11-8.336 1.363c-1.683 5.477-1.683 13.478 0 14.11 1.685.632 11.372 0 11.372 0S16.038 5.182 9.598 1.915"
            fill="#E02828"
            mask="url(#404__d)"
          />
        </g>
        <path
          d="M214.225 364.408s-14.949-10.528-18.564 6.35c0 0 3.5 9.38 5.283 26.527a287.683 287.683 0 0118.366 4.388c-.002-15.874-1.244-30.382-5.085-37.265"
          fill="#109AC4"
        />
        <path
          d="M224.353 468.177l-9.162-4.04s4.122-33.503 4.12-62.465a289.213 289.213 0 00-18.367-4.388c1.557 14.983 1.8 35.892-2.752 61.677 0 0-5.877 15.78.619 22.345 6.493 6.563 12.28 4.29 20.48 7.573 8.196 3.283 21.939 4.04 27.485-5.3 5.546-9.345-5.787-25.25-22.423-15.402M123.568 353.763s-14.95-11.054-18.564 6.667c0 0 4.551 12.834 5.913 35.966a167.844 167.844 0 0117.736-3.037c.02-16.848-1.208-32.301-5.085-39.596"
          fill="#109AC4"
        />
        <path
          d="M133.695 462.715l-9.162-4.241s4.081-34.815 4.12-65.115a168.282 168.282 0 00-17.735 3.037c.873 14.855.434 33.941-3.384 56.644 0 0-5.875 16.567.618 23.46 6.494 6.892 12.281 4.507 20.48 7.952 8.197 3.447 21.94 4.241 27.486-5.566 5.546-9.808-5.787-26.51-22.423-16.171"
          fill="#109AC4"
        />
        <path
          d="M189.712 186.287c6.318-11.372 8.844-25.268 3.79-37.902a18.055 18.055 0 00-1.239-2.277c-25.474 8.82-63.227 32.34-81.954 94.968 31.858-8.21 60.84-24.626 79.403-54.79"
          fill="#00AEF1"
        />
        <path
          d="M228.234 130.921c.02 1.528.338 2.876.338 2.876s-.095-1.1-.338-2.876"
          fill="#E28E33"
        />
        <path
          d="M238.82 150.397s14.708 1.199 12.899-5.05c-1.81-6.248-7.596-4.544-7.596-4.544s15.19-12.119 6.149-17.42c-9.043-5.303-14.828 11.171-14.828 11.171s.723-7.573-3.98-7.953c-2.72-.219-3.256 2.223-3.23 4.32a63.74 63.74 0 01.337 2.875s-.318-1.348-.338-2.875c-1.05-7.704-4.878-28.098-15.757-26.096-13.38 2.462 6.33 31.433 6.33 31.433s-3.978-3.218-5.425.19c-1.447 3.407 4.702 4.355 4.702 4.355s-10.766.092-25.818 5.305c.447.702.864 1.456 1.237 2.277 5.054 12.634 2.527 26.53-3.791 37.901-18.56 30.164-47.545 46.58-79.403 54.79-3.296 11.025-6.012 23.24-7.958 36.821 0 0-9.468 77.3-7.234 81.045 4.588 7.686 56.286 17.197 114.026 15.066 5.377-.198 10.11-3.793 11.943-9.092l27.567-71.377c9.175-23.76 14.335-49.052 14.796-74.675.495-27.448-4.136-57.611-24.629-68.467"
          fill="#00AEF1"
        />
        <path
          d="M141.45 244.918c-2.264-4.486-7.39-8.286-18.684-6.63-15.955 2.337-28.93 24.22-35.84 38.628 21.217-6.532 40.77-16.689 54.524-31.998"
          fill="#109AC4"
        />
        <path
          d="M92.43 291.148c8.861-15.505 26.459-27.124 45.984-27.203 1.584-1.42 3.299-3.854 4.507-8.073 0 0 1.073-5.908-1.472-10.953-13.754 15.307-33.306 25.464-54.524 31.995-3.48 7.257-5.427 12.625-5.427 12.625s-8.638-1.006-10.557 10.049c0 0-.96 5.528 2.4 7.54 0 0-33.11 48.237-25.912 67.33 0 0-1.28 5.862 4.317 7.873 0 0 3.68 8.04 9.117 5.191 0 0-4.477 11.054 1.28 13.232 5.76 2.178 12.157 1.341 14.396-8.039.57-2.383 1.73-10.514 3.863-21.625-5.064-26.83-3.138-54.96 12.029-79.942"
          fill="#109AC4"
        />
        <path
          d="M92.43 291.148c-15.166 24.982-17.092 53.111-12.028 79.942 6.267-32.609 20.95-90.924 53.881-105.167 0 0 1.94-.013 4.131-1.978-19.525.079-37.123 11.698-45.983 27.203"
          fill="#109AC4"
        />
        <path
          d="M109.14 245.071s20.054-15.586 32.31-.153c0 0-8.179-10.705-32.31.153"
          fill="#00AEF1"
        />
        <g transform="translate(212 104)">
          <mask id="404__f" fill="#fff">
            <use xlinkHref="#404__e" />
          </mask>
          <path
            d="M.477.825s12.61-5.296 15.926 28.053c0 0-2.088-7.056 3.062-6.277 5.15.78 3.978 7.953 3.978 7.953S27.007 15.25 37.18 18.841c10.177 3.593-1.813 15.592-5.055 17.962 0 0 7.015-1.236 7.728 5.305 0 0-3.944-6.408-9.55-3.566 0 0 12.694-15.327 5.685-16.74-7.009-1.413-12.872 12.001-12.872 12.001s-1.008-9.202-4.028-7.895c-3.017 1.307-2.515 7.66-2.515 7.66S10.688 2.037.477.824"
            fill="#109AC4"
            mask="url(#404__f)"
          />
        </g>
        <path
          d="M89.241 374.32c-6.081-7.165-15.007-6.522-15.007-6.522-3.474 9.689-2.844 17.789-2.844 17.789s-34.425 64.433-16.74 54.958c13.489-7.225 28.74-2.146 35.224.67a2.457 2.457 0 002.783-.573c-11.66-20.8-15-45.63-3.416-66.321"
          fill="#E02828"
        />
        <path
          d="M94.763 392.22c-.106-8.687-2.452-14.283-5.521-17.899-11.586 20.691-8.244 45.522 3.415 66.321.374-.396.632-.92.673-1.525.538-7.783 1.676-26.967 1.433-46.897"
          fill="#ED3E3E"
        />
        <path
          d="M201.83 217.82c-1.74 4.155-1.92 8.061-.404 8.725 1.52.666 4.162-2.162 5.904-6.318 1.741-4.156 1.921-8.064.402-8.728-1.518-.663-4.162 2.165-5.901 6.32M236.539 226.188c-1.422 4.275-1.308 8.185.258 8.732 1.566.55 3.985-2.473 5.41-6.748 1.42-4.275 1.304-8.185-.262-8.734-1.563-.547-3.984 2.475-5.406 6.75M228.8 200.92c-1.421 4.274-1.306 8.185.26 8.731 1.566.55 3.984-2.472 5.409-6.747 1.42-4.275 1.305-8.186-.261-8.735-1.564-.544-3.985 2.475-5.407 6.75M217.332 262.336c-3.973-3.568-9.207-5.485-14.53-5.078l-.252.02c-8.424.69-11.014-12.123.166-14.764 11.183-2.64 24.224 3.415 27.522 12.456 2.887 7.908-5.177 14.312-12.906 7.366"
          fill="#181818"
        />
        <g transform="translate(195 241)">
          <mask id="404__h" fill="#fff">
            <use xlinkHref="#404__g" />
          </mask>
          <path
            d="M30.209 24.604c-1.5 3.884-8.865 4.609-16.45 1.618-7.582-2.99-12.512-8.564-11.011-12.448 1.5-3.884 8.867-4.609 16.45-1.618 7.582 2.991 12.512 8.564 11.011 12.448"
            fill="#D04642"
            mask="url(#404__h)"
          />
        </g>
        <g>
          <g transform="translate(190.75 237)">
            <mask id="404__j" fill="#fff">
              <use xlinkHref="#404__i" />
            </mask>
            <path
              d="M81.153 52.152C89.96 15.333 65.218.14 65.218.14L62.55 7.835 50.694 41.997c-4.146 24.364-18.825 11.6-18.825 11.6-1.2-3.765-2.64 0-2.64 0v-24.47c0-9.662-2.398-13.679-6.957-11.168-4.558 2.51-3.118 22.337-3.118 22.337-3.24-4.894-5.88.125-5.88.125s-.958-2.133-3.237-1.883C7.755 38.79 7.636 41.3 7.636 41.3c-.72-1.256-3.838-5.27-6.718 0-2.878 5.27 5.04 33.88 18.02 42.413 2.57 1.69 6.205 2.94 10.447 3.578 21.381-4.803 40.565-16.255 51.768-35.14"
              fill="#109AC4"
              mask="url(#404__j)"
            />
          </g>
          <path
            d="M220.134 324.292c13.448 2.025 33.023-2.15 44.4-18.359 3.15-4.49 5.685-9.878 7.271-16.34.036-.148.063-.292.097-.44-11.2 18.884-30.386 30.335-51.768 35.139"
            fill="#00AEF1"
          />
          <path
            d="M244.511 295.389s-12.793 4.275-24.531-4.792c0 0 4.24-36.506-4.802-36.353 0 0-7.78-1.731-5.357 21.613 0 0-3.645-2.335-5.796 1.564 0 0-3.89-4.475-5.587.501 0 0-5.52-5.041-7.36 1.268 0 0-7.049 36.987 32.668 45.588 0 0-32.929-7.963-31.664-44.72 0 0 .315-2.208 2.684-2.327 2.367-.119 3.71 2.644 3.71 2.644s.79-3.63 2.606-3.197c1.816.434 3.08 2.958 3.08 2.958s.632-4.1 3.357-3.43c2.723.67 2.801 3.826 2.801 3.826s-1.42-26.266 4.581-25.32c6.001.947 4.185 37.387 4.185 37.387s13.739 11.465 25.425 2.79M261.363 244.16s-4.69 27.25-15.747 51.227c0 0 15.385-24.523 15.747-51.227"
            fill="#00AEF1"
          />
          <path
            d="M93.378 439.118c0 7.916-9.799 14.337-21.89 14.337-12.09 0-21.89-6.421-21.89-14.337 0-7.919 9.8-14.338 21.89-14.338 12.091 0 21.89 6.42 21.89 14.338"
            fill="#C91F1F"
          />
          <path
            d="M67.584 439.666v29.676l6.795-28.687a2.346 2.346 0 00-1.18-2.618c-.91-.474-2.16-.888-3.587-.704-1.164.151-2.028 1.162-2.028 2.333"
            fill="#EAC71B"
          />
          <path
            d="M73.585 464.978a5.525 5.525 0 01-5.528 5.52 5.524 5.524 0 01-5.526-5.52 5.525 5.525 0 015.526-5.522 5.525 5.525 0 015.528 5.522"
            fill="#EAC71B"
          />
        </g>
        <text
          fontFamily="Poppins"
          fontSize={237}
          fontWeight={400}
          fill="#000"
          transform="translate(40 63)"
        >
          <tspan x={306} y={321}>
            {"404"}
          </tspan>
        </text>
        <text
          fontFamily="Poppins"
          fontSize={47}
          fontWeight={400}
          fill="#000"
          transform="translate(40 63)"
        >
          <tspan x={321} y={92}>
            {"OOPS"}
          </tspan>
        </text>
        <g>
          <g opacity={0.393} transform="translate(921 462)">
            <mask id="404__l" fill="#fff">
              <use xlinkHref="#404__k" />
            </mask>
            <path
              d="M182.15 19.204c0 10.381-40.638 18.797-90.764 18.797-50.13 0-90.767-8.416-90.767-18.797S41.257.407 91.386.407c50.126 0 90.764 8.416 90.764 18.797"
              fill="#B7B7B7"
              mask="url(#404__l)"
            />
          </g>
          <path
            d="M898 194.657c-6.347-4.61-14.05-8.3-23.316-9.829-18.861-3.108-31.746 3.072-39.684 9.56 20.984 1.912 42.055 2.272 63 .269"
            fill="#109AC4"
          />
          <g transform="translate(808 194)">
            <mask id="404__n" fill="#fff">
              <use xlinkHref="#404__m" />
            </mask>
            <path
              d="M89.463.435C68.531 2.503 47.473 2.13 26.503.157c-7.02 5.927-10.17 12.1-10.17 12.1S-.654 24.028.017 29.185c.671 5.158 3.8 3.365 3.8 3.365 0 5.041 2.68 4.035 2.68 4.035S2.59 56.424 7.95 56.648c5.367.224 9.33-16.003 9.33-16.003s-.208 4.035 6.246 2.819c6.451-1.221 5.765-15.962 5.765-15.962C35.995 8.447 55.66 10.8 55.66 10.8c18.37 1.61 32.216 18.257 41.996 36.554 3.623-10.488 5.93-21.4 6.038-32.27-3.661-5.037-8.366-10.247-14.23-14.65"
              fill="#109AC4"
              mask="url(#404__n)"
            />
          </g>
          <path
            d="M922.258 229.14s-3.068-9.677-10.406-20.14c-.105 10.921-2.34 21.889-5.852 32.43 12.32 23.886 17.99 50.57 17.99 50.57 3.032-38.752-1.732-62.86-1.732-62.86M1064.83 226s-7.406 19.676 1.743 43.536c0 0 2.974.39 7.031 2.464 2.04-12.966 5.224-25.707 9.396-38.212-5.23-3.313-11.244-5.976-18.17-7.788M1090 240c4.873 27.106 12.751 55.007 25.849 80 1.015-25.343-2.593-60.044-25.849-80"
            fill="#109AC4"
          />
          <g transform="translate(1073 233)">
            <mask id="404__p" fill="#fff">
              <use xlinkHref="#404__o" />
            </mask>
            <path
              d="M17.448 6.41A59.318 59.318 0 009.88.908C5.58 13.36 2.299 26.049.196 38.96c11.43 5.644 31.211 23.826 19.703 80.932 0 0-11.74 8.967-11.737 16.027.002 7.06 3.878 8.91 3.878 8.91s-3.034 7.062 3.031 7.062c6.061 0 11.116 1.344 12.801 2.186 1.683.837 6.061-1.18 8.42 0 2.36 1.175 7.916 1.175 9.94-.676 2.02-1.848.674-27.402-6.402-35.135 0 0 3.065-13.327 3.793-31.377C30.36 61.747 22.383 33.68 17.448 6.41"
              fill="#109AC4"
              mask="url(#404__p)"
            />
          </g>
          <path
            d="M963.669 464.24s-2.858-4.734-5.607-1.76c0 0-20.117-13.096-29.681 8.693-9.564 21.784 33.31 22.777 43.862 12.544 10.554-10.235 13.852-10.565 13.852-10.565s4.287-6.932 0-9.903c-3.173-2.199-11.769-34.615-13.612-69.249-5.943.633-11.84 1.489-17.677 2.634l8.863 67.606z"
            fill="#00AEF1"
          />
          <path
            d="M973 358.213s-19.666-17.209-25-6.95L954.043 397c5.9-1.145 11.86-2.004 17.866-2.638-.653-12.18-.468-24.634 1.091-36.149M1038.33 454.238s2.858-4.731 5.607-1.758c0 0 20.116-13.095 29.683 8.693 9.561 21.784-33.312 22.777-43.861 12.545-10.554-10.236-13.854-10.565-13.854-10.565s-4.287-6.933 0-9.903c3.175-2.199 11.768-34.614 13.611-69.25 5.945.635 11.84 1.491 17.677 2.636l-8.862 67.602zM1029 347.213s19.669-17.209 25-6.95L1047.957 386c-5.9-1.145-11.858-2.004-17.866-2.64.653-12.179.468-24.632-1.091-36.147"
            fill="#00AEF1"
          />
          <path
            d="M974.176 106.855c.33.813.648 1.29.648 1.29s-.222-.465-.648-1.29"
            fill="#E28E33"
          />
          <path
            d="M1052.794 200.328A170.444 170.444 0 001069 209c-4.51-33.454-14.813-65.23-35.871-83.928a66.446 66.446 0 00-13.065-9.072c-10.314 28.512 6.62 68.86 32.73 84.328"
            fill="#00AEF1"
          />
          <path
            d="M1052.873 200.943c-25.955-15.586-42.789-56.238-32.536-84.966a64.93 64.93 0 00-11.918-4.963s33.007-31.594 13.798-44.852c-19.208-13.256-31.13 38.776-31.13 38.776s-5.96-13.258-12.914-7.954c-5.198 3.962-4.29 8.292-3.501 10.23.34.666.52 1.038.52 1.038s-.255-.384-.52-1.039c-3.326-6.498-21.974-41.058-37.894-31.44-17.553 10.607 24.173 42.09 24.173 42.09s-10.48 7.588-21.205 27.052c-17.236 31.283-35.108 93.254-11.12 203.739 27.715 6.025 57.025 5.789 86.337-.73 23.627-5.254 42.07-20.147 54.706-39.445 2.37-25.866 4.042-63.27-.686-98.799a168.695 168.695 0 01-16.11-8.737"
            fill="#00AEF1"
          />
          <path
            d="M1015.31 348.385c-29.303 6.509-58.604 6.745-86.31.729a618.15 618.15 0 002.521 11.12s19.203 3.972 69.853 0c50.654-3.968 64.556-18.2 64.556-18.2s2.239-13.08 4.07-33.034c-12.631 19.27-31.07 34.138-54.69 39.385"
            fill="#109AC4"
          />
          <path
            d="M989.017 171.565c.177 4.142 1.656 7.47 3.302 7.435 1.646-.036 2.839-3.424 2.664-7.565-.174-4.14-1.656-7.468-3.305-7.435-1.643.039-2.836 3.423-2.661 7.565M967.017 199.565c.174 4.142 1.656 7.47 3.302 7.435 1.646-.036 2.839-3.423 2.664-7.565-.177-4.14-1.656-7.47-3.305-7.435-1.643.036-2.836 3.423-2.661 7.565M1014.68 192c-1.647.036-2.84 3.423-2.663 7.565.175 4.14 1.655 7.47 3.3 7.435 1.65-.036 2.843-3.423 2.666-7.565-.178-4.142-1.655-7.47-3.303-7.435"
            fill="#181818"
          />
          <path
            d="M973 229s17.846-12.044 42-2.58c0 0-22.889-13.767-42 2.58"
            fill="#BA6315"
          />
          <path
            d="M969 227s6.429.443 5.977 7c0 0-1.067-4.519-5.977-7M1014.023 231s1.064-4.519 5.977-7c0 0-6.432.443-5.977 7M986 232s8.001-3.71 17 0c0 0-8.6-6.75-17 0"
            fill="#BA6315"
          />
          <path
            d="M936 75.861S953.228 62.376 975 108c0 0-17.387-31.842-39-32.139"
            fill="#109AC4"
          />
          <g transform="translate(974 63)">
            <mask id="404__r" fill="#fff">
              <use xlinkHref="#404__q" />
            </mask>
            <path
              d="M51.669 24.411c4.454-8.296 3.169-26.062-11.45-23.37C25.6 3.737 17.021 42.496 17.021 42.496c-5.959-11.102-10.11-11.529-14.59-6.485-4.482 5.042-1.236 9.855-1.236 9.855-.067-6.33 4.558-8.168 4.558-8.168 9.005-.808 12.308 15.532 12.308 15.532S27.617 8.26 40.504 5.153C53.391 2.048 51.67 24.41 51.67 24.41"
              fill="#109AC4"
              mask="url(#404__r)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

const PageNotFoundSvg = React.memo(PageNotFoundComponent);
export default PageNotFoundSvg;
