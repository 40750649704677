import { Box, Button } from "@material-ui/core";
import Seo from "components/Seo";
import PageNotFoundSvg from "components/svg/PageNotFound";
import Head from "next/head";

export default function Custom404() {
  return (
    <>
      <Seo title={"404 - Oops, Page Not Found"} />
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <PageNotFoundSvg />
        <Button variant="outlined" size="large" color="primary">
          Go To Home Page
        </Button>
      </Box>
    </>
  );
}
